<script setup lang="ts">
const props = defineProps({
  pageName: String,
})

async function goNext() {
  const gtm = useGtm();
  gtm?.trackEvent({
    event: 'interaction',
    category: 'button',
    action: 'click',
    label: props.pageName,
    value: 'Next Button Clicked'
  })

  navigateTo('/easy');
}
</script>
<template>
  <NuxtLayout name="default">
    <template v-slot:header>
      Cannagram to społeczność pacjentów, to sprawdzone informacje o dostępności i pewne informacje o cenie Medycznej.
    </template>
    <template v-slot:contentLeft>
      <div class="flex-col justify-start items-start gap-5 inline-flex">
        <div class="justify-start items-start gap-3 inline-flex">
          <div class="w-10 h-10 p-2 justify-center items-center flex bg-[url('~/assets/jingle.svg')]">
          </div>
          <div class="grow shrink basis-0 text-stone-950 text-lg font-normal font-['Space_Grotesk'] leading-normal">
            Aktualne, zrozumiałe, wiarygodne informacje o dostępności Medycznej, opinie pacjentów, oceny leków, lekarzy oraz aptek – wszystko w jednym miejscu.
          </div>
        </div>
        <div class="justify-start items-start gap-3 inline-flex">
          <div class="w-10 h-10 p-2 justify-center items-center flex bg-[url('~/assets/jingle.svg')]">
          </div>
          <div class="grow shrink basis-0 text-stone-950 text-lg font-normal font-['Space_Grotesk'] leading-normal">
            Każdy użytkownik Cannagramu ma wpływ na aktualność informacji.
          </div>
        </div>
        <div class="justify-start items-start gap-3 inline-flex">
          <div class="w-10 h-10 p-2 justify-center items-center flex bg-[url('~/assets/jingle.svg')]">
          </div>
          <div class="grow shrink basis-0 text-stone-950 text-lg font-normal font-['Space_Grotesk'] leading-normal">
            Ty też możesz podzielić się informacjami i używać Cannagramu za darmo.
          </div>
        </div>
      </div>
    </template>
    <template v-slot:contentRight>
      <client-only>
        <img
          src="/app.png"
          alt="Applikacja Cannagram"
          class="w-fit"
        />
      </client-only>
    </template>
    <template v-slot:footer>
      <UButton
        block
        type="submit"
        class="w-2/3 md:w-1/3 text-sm hover:shadow-600 justify-center"
        icon="i-heroicons-arrow-right-20-solid"
        size="md"
        trailing
        @click="goNext"
      >
        Dalej
      </UButton>
    </template>
  </NuxtLayout>
</template>